import React from 'react';
import Head from 'next/head';
import { Container, Header, Navbar } from '@components';
import { SignIn } from '@components/authentication/signin';
import { GoogleOAuthProvider } from '@react-oauth/google';

const Login: React.FC = () => {
  
  return (
    <Container>
      <Head>
        <title>Login - Jhattse</title>
        <meta
          name="description"
          content="Login on Jhattse. Jhattse is a local ecommerce platform which provides product visibility, best price discovering and all available deals and offers."
        />
      </Head>
      <Header />
      <div className="mx-auto max-w-[1240px] lt-sm:w-full py-4 mt-0 md:mt-18">
        <div className="px-2 max-w-[500px] mx-auto">
          <GoogleOAuthProvider clientId="624666456033-814o92j1gov9dq125a20b3l2c50r1k6j.apps.googleusercontent.com">
            <SignIn />
          </GoogleOAuthProvider>
        </div>
      </div>
      <Navbar />
    </Container>
  );
};

export default Login;
